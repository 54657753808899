<template>
  <div>
    <div class="row m-l-0 m-r-0">
      <div class="col-xl-6 col-lg-12 p-l-0 retrait-input">
        <slot />
      </div>
      <div v-if="operator_id" class="col-xl-6 col-lg-12 p-r-0 retrait-input">
        <div class="row align-items-end">
          <div class="col  pt-1 pb-1 pr-0">
            <be-multiselect
              v-model="contact_id"
              :close-on-select="true"
              :disabled="!operator_id"
              :inputLabel="$t('transaction.receiver')"
              :options="remappedContacts"
              :placeholder="$t('common.choose_o')"
              label="label"
              onlyKey
              track-by="id"
            />
          </div>
          <div class="col-auto p-1" v-if="contactSelected">
            <button
              :data-original-title="$t('common.delete_account')"
              :disabled="contactSelected === null || showContactForm"
              :title="$t('common.delete_account')"
              class="be-btn secondary danger circle-btn lg"
              role="button"
              type="button"
              @click.prevent="removeContactServiceNumber"
            >
              <i class="icon-person-delete"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showContactForm" class="col-md-12">
      <contact-form
        :contact="contactSelected"
        :operator_id="operator_id"
        @close="contact_id = null"
        @saving="setServiceNumber"
      />
    </div>
  </div>
</template>

<script>
import ContactForm from "./ContactForm";
import { mapActions, mapGetters, mapState } from "vuex";
import BeMultiselect from "../common/BeMultiselect";

export default {
  name: "ContactSelector",
  components: { ContactForm, BeMultiselect },
  data() {
    return {
      loading: false,
      contact_id: null,
    };
  },
  props: {
    operator_id: {
      type: Number,
      default: null,
    },
  },
  watch: {
    serviceNumber(value) {
      this.$emit(
        "select",
        value
          ? {
              ...value,
              contact: this.contactSelected,
            }
          : null
      );
    },
    operator_id() {
      this.contact_id = null;
    },
  },

  computed: {
    ...mapState("withdraw", ["contacts"]),
    ...mapGetters("withdraw", ["getContactById"]),
    contactSelected() {
      return this.getContactById(this.contact_id);
    },
    serviceNumber() {
      if (this.contactSelected) {
        const serviceNumber = this.getServiceNumber(this.contactSelected);
        if (serviceNumber.id) return serviceNumber;
      }
      return null;
    },
    showContactForm() {
      if (this.contactSelected) {
        return this.serviceNumber === null;
      }
      return this.contact_id === -1;
    },
    remappedContacts() {
      const contacts = this.contacts.map(contact => ({
        label: `${contact.firstname} ${contact.lastname} / ${
          this.getServiceNumber(contact).svc_number
        }`,
        id: contact.id,
      }));
      contacts.push({ label: this.$t("common.add_contact_with_plus"), id: -1 });
      return contacts;
    },
  },

  methods: {
    ...mapActions("withdraw", ["deleteServiceNumber"]),

    getServiceNumber(contact) {
      const info = contact.service_numbers.find(
        inf => inf.operator_id === this.operator_id
      );
      if (info !== undefined) return info;
      return { svc_number: this.$t("common.not_defined") };
    },

    removeContactServiceNumber() {
      if (!this.loading) {
        this.loading = true;
        this.$store
          .dispatch("withdraw/deleteServiceNumber", this.serviceNumber)
          .then(() => {
            this.contact_id = null;
            this.loading = false;
          });
      }
    },

    setServiceNumber(serviceNumber) {
      this.contact_id = serviceNumber.contact_id;
    },
  },
};
</script>

<style scoped></style>
