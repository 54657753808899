<template>
  <form @submit.prevent="handleContactForm">
    <hr />
    <div class="form-group row align-items-center">
      <template v-if="canShowUpdateForm">
        <div class="col-xl-6 col-lg-12 p-l-0 retrait-input">
          <BaseInput
            v-model="contactField.lastname"
            :errorMessage="lastnameErrorMessage"
            :isInvalid="
              $v.contactField.lastname.$error || errors.lastname !== undefined
            "
            :label="$t('common.name')"
            :placeholder="$t('common.name')"
            type="text"
            @input="$v.contactField.lastname.$touch()"
          />
        </div>
        <div class="col-xl-6 col-lg-12 p-r-0 retrait-input">
          <BaseInput
            v-model="contactField.firstname"
            :errorMessage="firstnameErrorMessage"
            :isInvalid="
              $v.contactField.firstname.$error || errors.firstname !== undefined
            "
            :label="$t('common.surname')"
            :placeholder="$t('common.surname')"
            type="text"
            @input="$v.contactField.firstname.$touch()"
          />
        </div>
      </template>
      <div class="col-xl-6 col-lg-12 p-l-0 retrait-input">
        <BaseInput
          v-model="svc_number"
          :errorMessage="svcNumberErrorMessage"
          :isInvalid="$v.svc_number.$error || errors.svc_number !== undefined"
          :label="$t('common.number') + ' ' + operator.label"
          :placeholder="$t('common.number') + ' ' + operator.label"
          type="number"
          @input="$v.svc_number.$touch()"
        />
      </div>
      <div class="col-xl-6 col-lg-12 p-r-0 retrait-input">
        <div class="col-sm-6 text-lg-right p-l-0 retrait-button-container">
          <button
            v-if="canCancelForm"
            class="be-btn primary  badge-failure lg contact-button"
            @click.prevent="$emit('close')"
          >
            {{ $t("common.cancel") }}
          </button>
        </div>
        <div class="col-sm-6 text-lg-right p-r-0 retrait-button-container">
          <button
            :disabled="$v.$invalid || loading"
            class="be-btn primary green lg contact-button"
          >
            {{ $t("common.save") }}
            <span v-if="loading" class="fa fa-spinner fa-spin" role="status" />
          </button>
        </div>
      </div>
    </div>

    <div class="form-group row"></div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "ContactForm",

  props: {
    contact: {
      type: Object,
      default: null,
    },
    operator_id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      errors: [],
      contactField: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
      },
      svc_number: null,
      showUpdateContactForm: false,
    };
  },

  watch: {
    contact(value) {
      if (value) this.contactField = { ...value };
    },
  },

  computed: {
    ...mapGetters("withdraw", ["getOperatorById"]),
    ...mapGetters("auth", ["calling_code", "phone_length"]),
    operator() {
      return this.getOperatorById(this.operator_id);
    },
    canShowUpdateForm() {
      return !this.contact || !this.contact.id;
    },
    canCancelForm() {
      return (
        this.canShowUpdateForm ||
        (this.contact && this.contact.service_numbers.length)
      );
    },
    lastnameErrorMessage() {
      if (this.$v.contactField.lastname.$error || this.errors.lastname) {
        if (!this.$v.contactField.lastname.required) {
          return this.$t("utils.required.name");
        }
        if (this.errors.lastname) {
          return this.errors.lastname[0];
        }
      }
      return "";
    },
    firstnameErrorMessage() {
      if (this.$v.contactField.firstname.$error || this.errors.firstname) {
        if (!this.$v.contactField.firstname.required) {
          return this.$t("utils.required.surname");
        }
        if (this.errors.firstname) {
          return this.errors.firstname[0];
        }
      }
      return "";
    },
    svcNumberErrorMessage() {
      if (this.$v.svc_number.$error || this.errors.svc_number) {
        if (!this.$v.svc_number.required) {
          return this.$t("utils.required.number");
        }
        if (!this.$v.svc_number.minLength || !this.$v.svc_number.maxLength) {
          return (
            this.$t("utils.required.numbers_length_before") +
            ` ${this.phone_length} ` +
            this.$t("utils.characters")
          );
        }
        if (this.errors.svc_number) {
          return this.errors.svc_number[0];
        }
      }
      return "";
    },
  },

  created() {
    if (this.contact) this.contactField = { ...this.contact };
  },

  methods: {
    handleContactForm() {
      this.$v.$touch();
      if (!this.$v.invalid && !this.loading) {
        this.loading = true;
        if (this.contactField.id) {
          this.createServiceNumber({
            contact_id: this.contactField.id,
            svc_number: this.svc_number,
            operator_id: this.operator_id,
          });
        } else {
          this.$store
            .dispatch("withdraw/addContact", this.contactField)
            .then(contact => {
              this.createServiceNumber({
                contact_id: contact.id,
                svc_number: this.svc_number,
                operator_id: this.operator_id,
              });
            })
            .catch(err => {
              if (err.response.data.errors)
                this.errors = err.response.data.errors;
              this.loading = false;
            });
        }
      }
    },
    createServiceNumber(serviceNumberField) {
      this.$store
        .dispatch("withdraw/addServiceNumber", serviceNumberField)
        .then(serviceNumber => {
          this.$emit("saving", serviceNumber);
          this.loading = false;
        })
        .catch(err => {
          if (err.response.data.errors) this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },

  validations() {
    return {
      contactField: {
        firstname: { required },
        lastname: { required },
      },
      svc_number: {
        required,
        minLength: minLength(this.phone_length),
        maxLength: maxLength(this.phone_length),
      },
    };
  },
};
</script>

<style scoped></style>
