<template>
  <div class="retraits-service be-row justify-content-center">
    <div class="left-side d-none d-xl-flex">
      <div class="icon-div">
        <i class="icon-withdraw-fill"></i>
      </div>
      <span>
        {{ $t("common.new") }} <br />
        {{ $t("withdraw.money_withdraw") }}
      </span>
    </div>
    <div class="right-side col-xl-8 px-0">
      <div class="be-card col-xl-12 px-0">
        <div class="position-relative">
          <header-with-divider
            :color="'blue'"
            :text="$t('withdraw.new_money_withdraw')"
          />
        </div>
        <div class="h-100">
          <div
            v-if="isWithdrawAvailable"
            class="be-card-body pt-0 pt-sm-3 pl-2 pr-2"
          >
            <form
              class="form m-20 position-relative"
              @submit.prevent="handleWithdrawForm"
            >
              <be-form-error v-if="error" :message="error" />
              <template v-if="!isConfirmation">
                <ContactSelector
                  :info="svcNumber"
                  :operator_id="withdrawField.operator_id"
                  @select="selectSvcNumber"
                >
                  <be-multiselect
                    v-model="withdrawField.operator_id"
                    :close-on-select="true"
                    :options="operators"
                    :inputLabel="$t('common.choose_an_operator')"
                    label="label"
                    onlyKey
                    track-by="id"
                    withImages
                  />
                </ContactSelector>
                <hr />
                <div class="form-group row align-items-center">
                  <div class="col-xl-6 col-lg-12">
                    <BaseInput
                      v-model.number="withdrawField.amount"
                      :disabled="!svcNumber"
                      :errorMessage="amountErrMsg"
                      :isInvalid="$v.withdrawField.amount.$error"
                      :label="$t('common.withdraw_amount')"
                      :placeholder="$t('common.insert_amount')"
                      :rightText="!svcNumber ? '' : feePercent + '%'"
                      type="number"
                      @input="$v.withdrawField.amount.$touch()"
                    />
                    <small
                      v-if="this.withdrawField.amount && !amountErrMsg"
                      class="form-text m-l-30"
                    >
                      {{ $t("common.balance_after_validation") }} :
                      <span class="f-w-600">
                        {{ `${balanceAfterWithdraw} ${currency}` }}
                      </span>
                    </small>
                  </div>
                </div>
              </template>
              <template v-else>
                <WithdrawConfirmation
                  :amount="withdrawField.amount"
                  :amount-to-paid="amountToPaid"
                  :fee-amount="feeAmount"
                  :fee-percent="feePercent"
                  :operator="operator"
                  :svc-number="svcNumber"
                >
                  <BaseInputPassword
                    v-model="withdrawField.password"
                    :errorMessage="passErrMsg"
                    :isInvalid="$v.withdrawField.password.$error"
                    :placeholder="$t('common.insert_password')"
                    @blur="$v.withdrawField.password.$touch()"
                  >
                  </BaseInputPassword>
                </WithdrawConfirmation>
              </template>
              <div class="form-group mb-0 text-lg-right">
                <hr />
                <div
                  class="retrait-main-buttons row justify-content-end align-items-center mr-md-2 m-t-20"
                >
                  <span
                    class="f-w-600 fermer pointer retrait-annuler"
                    @click="$router.push({ name: 'withdraw' }).catch(() => {})"
                  >
                    {{ $t("common.cancel") }}
                  </span>
                  <button
                    :class="isConfirmation ? 'green' : 'blue'"
                    :disabled="$v.$invalid || loading"
                    class="ml-4 be-btn primary lg"
                  >
                    {{
                      isConfirmation ? $t("common.confirm") : $t("common.start")
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <no-content v-else :text="listModel" icon="icon-withdraw" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ContactSelector from "@/components/withdraw/ContactSelector";
import {
  maxValue,
  minLength,
  minValue,
  required,
  requiredIf,
} from "vuelidate/lib/validators";
import NoContent from "@/components/common/NoContent";
import HeaderWithDivider from "@/components/common/HeaderWithDivider";
import BeMultiselect from "@/components/common/BeMultiselect";
import BeFormError from "@/components/common/BeFormError";
import WithdrawConfirmation from "@/components/withdraw/WithdrawConfirmation.vue";

export default {
  name: "WithdrawForm",
  components: {
    WithdrawConfirmation,
    ContactSelector,
    HeaderWithDivider,
    NoContent,
    BeMultiselect,
    BeFormError,
  },
  data() {
    return {
      loading: false,
      error: null,
      errors: [],
      withdrawField: {
        operator_id: null,
        service_number_id: null,
        amount: null,
        password: null,
      },
      svcNumber: null,
      isConfirmation: false,
    };
  },

  watch: {
    svcNumber(value) {
      this.withdrawField.service_number_id = value?.id ?? null;
      this.withdrawField.amount = null;
    },
  },

  computed: {
    ...mapState("withdraw", ["metaWithdraw"]),
    ...mapGetters("auth", ["userBalance", "currency"]),
    ...mapGetters("withdraw", [
      "isWithdrawAvailable",
      "operators",
      "userConfig",
      "getOperatorById",
    ]),
    listModel() {
      return (
        this.userConfig?.withdraw_error_message ??
        this.$t("base.messages.disableWithdraw")
      );
    },
    operator() {
      return this.getOperatorById(this.withdrawField.operator_id);
    },
    minWithdrawAmountValue() {
      return parseFloat(
        this.operator?.setting?.withdraw_min ??
          this.userConfig?.withdraw_min ??
          500
      );
    },
    maxWithdrawAmountValue() {
      const wm = parseFloat(
        this.operator?.setting?.withdraw_max ??
          this.userConfig?.withdraw_max ??
          500000
      );
      return this.userBalance > wm ? wm : this.userBalance;
    },
    amountValidationModulo() {
      return parseFloat(this.operator?.setting.withdraw_amount_mod ?? 100);
    },

    feePercent() {
      return parseFloat(this.userConfig.withdraw_fee_percent ?? "0");
    },
    feeAmount() {
      if (this.feePercent === 0) return 0;
      if (!this.withdrawField.amount) return 0;
      return (
        (parseFloat(this.withdrawField.amount) * this.feePercent) /
        100
      ).toFixed(0);
    },
    amountToPaid() {
      return this.withdrawField.amount
        ? parseFloat(this.withdrawField.amount) + parseFloat(this.feeAmount)
        : 0;
    },
    balanceAfterWithdraw() {
      return this.userBalance - this.amountToPaid;
    },
    amountErrMsg() {
      if (this.$v.withdrawField.amount.$error || this.errors.amount) {
        if (!this.$v.withdrawField.amount.required) {
          return this.$t("withdraw.insert_amount");
        }
        if (!this.$v.withdrawField.amount.minValue) {
          return (
            this.$t("withdraw.greatest_amount_required") +
            ` ${this.minWithdrawAmountValue} ${this.currency}`
          );
        }
        if (!this.$v.withdrawField.amount.maxValue) {
          return (
            this.$t("withdraw.insert_amount") +
            ` ${this.maxWithdrawAmountValue} ${this.currency}`
          );
        }
        if (!this.$v.withdrawField.amount.validAmount) {
          return this.$t("withdraw.require_valid_amount", {
            mod: this.amountValidationModulo,
          });
        }
        if (this.errors.amount) {
          return this.errors.amount[0];
        }
      }
      return "";
    },
    passErrMsg() {
      if (this.$v.withdrawField.password.$error || this.errors.password) {
        if (!this.$v.withdrawField.password.required) {
          return this.$t("common.insert_password");
        }
        if (!this.$v.withdrawField.password.minLength) {
          return this.$t("common.password_required_4_digits");
        }
        if (this.errors.password) {
          return this.errors.password[0];
        }
      }
      return "";
    },
  },

  methods: {
    selectSvcNumber(svcNumber) {
      this.svcNumber = svcNumber;
    },
    handleWithdrawForm() {
      this.loading = true;
      this.error = null;
      if (this.isConfirmation) {
        this.$store
          .dispatch("withdraw/confirmWithdraw", this.withdrawField)
          .then(() => {
            this.$router.push({ name: "withdraw" });
            this.loading = false;
          })
          .catch(error => {
            if (error.response?.data?.errors) {
              this.errors = error.response.data.errors;
            }
            if (error.message) {
              this.error = error.message;
            }
            this.loading = false;
            this.password = null;
          });
      } else {
        this.isConfirmation = true;
        this.loading = false;
        this.error = null;
      }
    },
  },

  validations() {
    return {
      withdrawField: {
        operator_id: { required },
        service_number_id: { required },
        amount: {
          required,
          minValue: minValue(this.minWithdrawAmountValue),
          maxValue: maxValue(this.maxWithdrawAmountValue),
          validAmount: value => {
            if (value) {
              return parseFloat(value) % this.amountValidationModulo === 0;
            } else return true;
          },
        },
        password: {
          required: requiredIf(function(model) {
            if (this.isConfirmation) return model;
            else return false;
          }),
          minLength: minLength(4),
        },
      },
    };
  },
};
</script>
