<template>
  <div class="confirm-container d-flex flex-column align-items-center p-0">
    <div class="shield-icon">
      <i class="icon-shield-check" />
    </div>
    <h2 class="f-w-600 text-center">
      {{ $t("withdraw.enter_password_to_confirm_withdraw") }}
    </h2>
    <h5 class="text-center">
      {{ $t("withdraw.withdraw_amount_in_your_account_before") }}:
      <b>{{ `${amountToPaid} ${currency}` }}</b>
      {{ $t("common.for_this_operation") }}
    </h5>
    <div class="retrait-pass-container">
      <slot />
    </div>
    <div class="text-center retraits-confirm-table">
      <b-row>
        <b-col>
          <div class="label-text">
            <div>{{ $t("common.receiver") }}</div>
            <div class="f-w-600">
              <span v-if="svcNumber">
                {{ contactName | truncate(16, "...") }}
              </span>
              <span v-else>{{ $t("common.not_specified") }}</span>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="label-text">
            <div>{{ $t("common.amount") }}</div>
            <div class="f-w-600">
              {{ `${amount} ${currency}` }}
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="label-text">
            <div>{{ $t("common.operator") }}</div>
            <div class="f-w-600">
              {{ operatorName }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div class="label-text">
            <div>{{ $t("common.phone_number") }}</div>
            <div class="f-w-600">{{ contactPhone }}</div>
          </div>
        </b-col>
        <b-col>
          <div class="label-text">
            <div>{{ $t("common.fees") }} ({{ feePercent }}%)</div>
            <div class="f-w-600">
              {{ `${feeAmount} ${currency}` }}
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="label-text">
            <div>{{ $t("common.status") }}</div>
            <div class="f-w-600">{{ $t("common.pending") }}</div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "WithdrawConfirmation",
  props: {
    operator: {
      type: Object,
      default: null,
    },
    svcNumber: {
      type: Object,
      default: null,
    },
    amount: { type: Number, default: 0 },
    amountToPaid: { type: Number, default: 0 },
    feeAmount: { type: Number, default: 0 },
    feePercent: { type: Number, default: 0 },
  },
  computed: {
    ...mapGetters("auth", ["currency", "calling_code"]),
    contactName() {
      return this.svcNumber.contact
        ? (this.svcNumber.contact.firstname || "") +
            " " +
            (this.svcNumber.contact.lastname || "")
        : null;
    },
    contactPhone() {
      return `+${this.calling_code} ${this.svcNumber.svc_number}`;
    },
    operatorName() {
      return this.operator?.label ?? this.$t("common.not_defined");
    },
  },
};
</script>
